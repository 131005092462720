export const LS_TRIMOZ_LANG = 'tmz_lang'

export const ModulesEnum = {
  SCREENING: 1,
  FLU: 2,
  KIDS: 3,
  PHARMA: 4,
  TRIMOZ: 5,
  YOUTH_CLINIC: 6,
  PREGNANCY: 7,
  SAMPLE: 8
}

export const twoFaMethod = {
  EMAIL: 'email',
  SMS: 'sms'
}

export const GlobalSettingsEnum = {
  ENABLE_COVID_SECOND_DOSE: 151,
  MIN_AGE_COVID_THIRD_DOSE_GENERAL: 163,
  MIN_AGE_COVID_THIRD_DOSE_ASTRAZENECA: 164,
  PORTAL_POSTAL_CODE_FOR_REMOTE_AREA: 167,
  PORTAL_DOSES_PRIORITY_GROUP: 178,
  SERVICE_UNIFIED_TAG: 195,
  MIN_AGE_TO_BOOK_BY_SERVICE_UNIFIED: 197,
  SHOW_PUBLIC_INSTITUTION: 205,
  ENABLE_COADMIN_2024_FEATURE_FLAG: 207,
  ENABLE_VRS_BEBE_FEATURE_FLAG: 208
}

export const ServiceUnifiedEnum = {
  COVID_FIRST_DOSE: 237,
  COVID_SECOND_DOSE: 238,
  COVID_THIRD_DOSE: 239,
  COVID_FOURTH_DOSE: 267,
  FLU: 65,
  COVID_VACCINE_FIRST_DOSE_KIDS: 261,
  COVID_VACCINE_SECOND_DOSE_KIDS: 262,
  COVID_VACCINE_THIRD_DOSE_KIDS: 266,
  COVID_VACCINE_ADDITIONAL_DOSE_IMMUNO: 263,
  COVID_VACCINE_6_MONTH_TO_4_YEARS: 298,
  COVID_VACCINE_5_TO_11_YEARS_OLD: 299,
  COVID_VACCINE_12_YEARS_AND_OLDER: 300,
  PHARMACIST_CONSULTATION: 335
}

export const ServiceUnifiedNameEnum = {
  SHINGLES_VACCINE: 'vaccin-zona'
}

export const CategoryEnum = {
  CATEGORY_PHARMACY_CONSULTATION: 'pharmacy-consultation'
}

export const Covid19ServiceUnifiedDescriptionEnum = {
  237: 'Covid 1st dose',
  238: 'Covid 2nd dose',
  239: 'Covid 3rd dose',
  261: 'Covid 1st dose kids',
  262: 'Covid 2nd dose kids',
  267: 'Covid 4th dose'
}

export const ServiceSelectorEnum = {
  SELECT_HEAD_OFFICE: 0,
  SELECT_CATEGORY: 1,
  SELECT_SUB_CATEGORY: 2,
  SET_POSTAL_CODE: 3,
  SET_SEARCH_BAR_RESULTS: 4,
  SET_SEARCH_POSTAL_CODE: 5
}

export const PlacesSettingsEnum = {
  CO_ADMINISTRATION_FLU_COVID: 188,
  IS_DISTANCE_HIDDEN: 198
}

export const ServiceCategoriesEnum = {
  COVID_19: 268,
  CONSULTATION_PHARMACIST: 335,
  SCREENING: 351,
  SPECIMENS_AND_BLOOD_TEST: 352,
  VACCINATION: 240
}

export const EstablishmentTypeEnum = {
  ALL: 'all',
  PHARMACY: 'pharmacie',
  PRIVATE_CLINIC: 'clinique_privee',
  HEALTH_ESTABLISHMENT: 'etablissement_de_sante',
  DEMO: 'demo'
}

export const ServicesFiltersEnum = {
  WITH_WITHOUT_FEES: 'all',
  WITH_WITHOUT_AVAILS: 'all',
  WITH_ALL_ESTABLISHMENTS_TYPE: 'all',
  WITHOUT_FEES: 'withoutFees',
  WITH_FEES: 'withFees',
  WITHOUT_AVAILS: 'withoutAvails',
  WITH_T07_AVAILS: 'withT0Avails',
  WITH_TA7_AVAILS: 'withTa7Avails',
  WITH_PHARMACY: 'withPharmacy',
  WITH_PRIVATE_CLINIC: 'withPrivateClinic',
  WITH_HEALTH_ESTABLISHMENT: 'withHealthEstablishment'
}

export const ThirdFactorInputMode = {
  Email: 'email',
  Cellphone: 'cellphone'
}

export const AllowedServiceUnifiedDescriptionAlert = {
  PHARMACIST_CONSULTATION: ServiceUnifiedEnum.PHARMACIST_CONSULTATION
}

export const NewsArticle = {
  ANNOUNCEMENT_CLIC_SANTE: 'announcement-clic-sante',
  PSL_CLIC_SANTE: 'psl-clic-sante',
  PHARMACIE_CLIC_SANTE: 'pharmacie-clic-sante',
  ACCOUNT_CLIC_SANTE: 'account-clic-sante',
  VACCINATION_CLIC_SANTE: 'vaccination-campaign-clic-sante'
}

export const NewsArticleEvent = {
  REGISTER: 'news-article-register'
}

export const ServicesTemplateEnum = {
  COADMIN_COVID_INFLUENZA: 3161
}

export const serviceTemplateIdsToExcludeForCoAdminEnum = [195, 204]

export const OrderEnum = {
  STRICT: 'strict',
  ALPHABETIC: 'alphabetic',
  NONE: 'none'
}

export const StatEnum = {
  APPOINTMENTS_LAST_7_DAYS: 'appts_of_last_7_days',
  PERCENTAGE_APPOINTMENTS_LAST_7_DAYS_WITHOUT_FEES: 'pct_appts_of_last_7_days_without_fees'
}

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

import { aliases, fa } from 'vuetify/iconsets/fa-svg'
import { config } from '@/config'
import { setProduct } from '@/theme'

const product = setProduct(window.location.hostname) || 'clicsante'
const defaultTheme = {
  primary: '#003E6C',
  secondary: '#00D2E6',
  accent: '#6fa7fc',
  error: '#cc0a00',
  info: '#eaf4ff',
  success: '#4caf50',
  warning: '#ff8500'
}

// Custom theme for Clic Santé
const csThemeColors = {
  primary2: '#fcc987',
  black: '#000000',
  white: '#ffffff',
  'grey-25': '#FCFCFD',
  'grey-50': '#f9fafb',
  'grey-100': '#f2f4f7',
  'grey-200': '#e4e7ec',
  'grey-300': '#d0d5Dd',
  'grey-400': '#98a2b3',
  'grey-500': '#667085',
  'grey-600': '#475467',
  'grey-700': '#344054',
  'grey-800': '#1d2939',
  'grey-900': '#101828',
  'text-default': '#031429',
  'blue-25': '#f3f7fc',
  'blue-50': '#e3f3ff',
  'blue-100': '#c5daf4',
  'blue-200': '#98bdeb',
  'blue-300': '#6ba0e3',
  'blue-400': '#3e83da',
  'blue-500': '#1167d2',
  'blue-600': '#0d52a8',
  'blue-700': '#0a3d7e',
  'blue-800': '#072d5c',
  'blue-900': '#031429'
}

const constValues = {
  letterSpacing: 'normal',
  'search-input-font-size': '1rem',
  'search-input-height': '3.25rem',
  'search-input-placeholder-opacity': '1',
  'search-input-field-height': '1.75rem',
  'search-input-field-padding': '0.75rem',
  'search-input-opacity': '1',
  'search-input-icon-size': '1rem',
  'search-input-icon-right-size': '1.5rem',
  'search-input-icon-margin': '0.25rem'
}

const theme = config ? config[product] : defaultTheme
export const vuetifyThemeOpts = {
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa
    }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          ...theme,
          ...csThemeColors,
          'action-btn-bg': csThemeColors['primary2'],
          'action-btn-text': csThemeColors['text-default'],
          // To remove with theme refactor
          'cs-white': '#ffffff',
          'cs-black': '#000000',
          'cs-grey': '#f1f1f1',
          'cs-grey-dark': '#d5d5d5',
          'cs-dark-gray': '#1d2939',
          'cs-primary': '#5eceff',
          'cs-secondary': '#00bdff',
          'cs-tertiary': '#107aca',
          'cs-fourtiary': '#00577d',
          'cs-quinary': '#fcc987',
          'cs-bg-primary': '#fcfcfd',
          'cs-bg-secondary': '#f2f7fd',
          'cs-bg-tertiary': '#e9f3ff',
          'cs-dark-primary': '#4b4b4b',
          'cs-dark-tertiary': '#464646',
          'cs-dark-fourtiary': '#101828',
          'cs-cookie-compliance': '#237afc'
        },
        variables: constValues
      },
      dark: {
        dark: true,
        colors: {
          ...theme,
          ...csThemeColors,
          'action-btn-bg': csThemeColors['primary2'],
          'action-btn-text': csThemeColors['text-default'],
          // To remove with theme refactor
          'cs-white': '#ffffff',
          'cs-black': '#000000',
          'cs-grey': '#f1f1f1',
          'cs-grey-dark': '#d5d5d5',
          'cs-dark-gray': '#1d2939',
          'cs-primary': '#5eceff',
          'cs-secondary': '#00bdff',
          'cs-tertiary': '#107aca',
          'cs-fourtiary': '#00577d',
          'cs-quinary': '#fcc987',
          'cs-bg-primary': '#fcfcfd',
          'cs-bg-secondary': '#f2f7fd',
          'cs-bg-tertiary': '#e9f3ff',
          'cs-dark-primary': '#4b4b4b',
          'cs-dark-tertiary': '#464646',
          'cs-dark-fourtiary': '#101828',
          'cs-cookie-compliance': '#237afc'
        },
        variables: constValues
      }
    }
  }
}

export default createVuetify({
  ...vuetifyThemeOpts
})
